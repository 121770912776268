import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Address from 'components/publicWebsiteV2/common/Address';

export const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: ${props => props.theme.SPACING.XL} auto 0;
  text-align: center;
  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    margin-top: 60px;
  }
`;

export const Poi = styled.div`
  margin-bottom: 60px;
  overflow-wrap: anywhere;
`;

export const Title = styled.h2`
  margin-top: 0; // Defensive styling for bootstrap styles applied in preview
  margin-bottom: ${({ theme }) => theme.SPACING.SM};
`;

export const AddressAndPhone = styled.div`
  margin-bottom: ${({ theme }) => theme.SPACING.SM};
  &:empty {
    display: none;
  }
`;

export const StyledAddress = styled(Address)`
  display: inline;
  margin: 0; // Defensive styling for bootstrap styles applied in preview
  &:empty {
    display: none;
  }
`;

export const Image = styled.img`
  border-radius: 50%;
  margin-bottom: ${({ theme }) => theme.SPACING.MD};
`;

export const buttonStyles = css`
  width: 200px;
  display: block;
  margin: 0 auto;
`;

export const MapContainer = styled.div`
  display: flex;
  justify-content: space-around;

  .poi-map {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
`;
